<template>
  <div class="fc-map__page-body">
    <div class="body-1 my-3">
      <v-card class="my-3 elevation-1">
        <v-card-title>
          <v-row>
            <v-col class="title">
              {{ $t('barrier.title') }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div>
                <fields v-model="instance" :fields="fields" :disabled="loading"/>
              </div>
            </v-col>
          </v-row> 
        </v-card-text>
      </v-card>
    </div>
    <v-row class="mt-3">
      <v-col class="d-flex flex-wrap ustify-end">
        <template v-if="editingInstance">
          <v-btn :loading="loading" text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
          <v-btn :loading="loading" color="primary" @click="save">{{ $t('actions.save') }}</v-btn>
        </template>
        <template v-else>
          <v-btn :loading="loading" class="mr-3" text @click="request">{{ $t('actions.request') }}</v-btn>
          <v-btn text @click="start">{{ $t('actions.edit') }}</v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  mixins: [generalFormMixin],
  props: ['id', 'tab'],
  data () {
    return {
      instance: {},
      editingInstance: false
    }
  },
  computed: {
    fields () { return configFields.barrier },
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    settings () {
      const out = []
      if (this.item && this.item.deviceSettings && this.item.deviceSettings.settings) {
        Object.keys(this.item.deviceSettings.settings).forEach((key) => {
          out.push({key, value: this.item.deviceSettings.settings[key]})
        })
      }
      return out
    }
  },
  methods: {
    request (target) {
      this.setLoading(true)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: `rtu.${target}Request`, data: {id: this.id}}))
        .then(() => this.$store.dispatch('rtu/fetch', {force: true}))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t(`success.${target}Requested`)})
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
