export function getItemStatus (item) {
  const deviceStatus = (item || {}).deviceStatus || {}
  if (!deviceStatus.online) {
    return {
      color: 'error',
      message: 'no_connection'
    }
  }
  if (deviceStatus.error) {
    return {
      color: 'error',
      message: deviceStatus.error
    }
  }
  if (deviceStatus.warning) {
    return {
      color: 'warning',
      message: deviceStatus.warning
    }
  }
  if (deviceStatus.engineStatus) {
    return {
      color: 'error',
      message: 'service_stop'
    }
  }
  if (deviceStatus.move === 'service_stop') {
    return {
      color: 'stop',
      message: 'service_stop'
    }
  }
  if (deviceStatus.move === 'barrier_stop') {
    return {
      color: 'stop',
      message: 'barrier_stop'
    }
  }
  if (deviceStatus.move === 'stop') {
    return {
      color: 'stop',
      message: 'stop'
    }
  }
  if (deviceStatus.acc === 'on') {
    if (deviceStatus.irrigation === 'on') {
      if (deviceStatus.move === 'frw') {
        return {
          color: 'acc',
          message: 'acc_frw'
        }
      }
      if (deviceStatus.move === 'rev') {
        return {
          color: 'acc',
          message: 'acc_rev'
        }
      }
    }
  }
  if (deviceStatus.irrigation === 'on') {
    if (deviceStatus.move === 'frw') {
      return {
        color: 'irrigation',
        message: 'irrigation_frw'
      }
    }
    if (deviceStatus.move === 'rev') {
      return {
        color: 'irrigation',
        message: 'irrigation_rev'
      }
    }
  }
  if (deviceStatus.move === 'frw') {
    return {
      color: 'move',
      message: 'frw'
    }
  }
  if (deviceStatus.move === 'rev') {
    return {
      color: 'move',
      message: 'rev'
    }
  }
  return {
    color: 'stop',
    message: 'stop'
  }
}