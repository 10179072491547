export default [{
  id: 'osm',
  name: 'Open Street Map',
  url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
  attribution: '<a href="http://osm.org/copyright">openstreetmap</a>'
}, {
  id: 'esri',
  name: 'Esri',
  url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  attribution: 'Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
}]
