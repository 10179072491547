<template>
  <div class="fc-map__page -full-width -full-height -outer-corners">
    <div class="fc-map__page-body">
      <scrollable-table
        :head-size="40"
        class="fc-map-table"
      >
        <template #head>
          <div class="fc-map-table__row fc-map-table__row--header">
            <div
              v-for="column in columns"
              :key="column.key"
              :style="{
                flex: column.flex
              }"
              :class="'fc-map-table__cell--' + column.key"
              class="fc-map-table__cell fc-map-table__cell--header"
            >
              <div
                v-if="column.skipSort"
                class="fc-map-table__header-link"
              >
                {{ $t('irrigationColumns.' + column.key) }}
              </div>
              <router-link
                v-else
                :to="{
                  query: {
                    order: order === column.key ? column.key + '-desc' : column.key
                  }
                }"
                class="fc-map-table__header-link"
              >
                {{ $t('irrigationColumns.' + column.key) }}
                <v-icon
                  v-if="order === column.key"
                  class="fc-map-table__header-link-icon"
                >
                  mdi-menu-down
                </v-icon>
                <v-icon
                  v-else-if="order === column.key + '-desc'"
                  class="fc-map-table__header-link-icon"
                >
                  mdi-menu-up
                </v-icon>
              </router-link>
            </div>
          </div>
        </template>
        <template #body>
          <div
            v-for="item in items"
            :key="item.id"
            class="fc-map-table__row"
          >
            <div
              v-for="column in columns"
              :key="column.key"
              :style="{
                flex: column.flex
              }"
              :class="'fc-map-table__cell--' + column.key"
              class="fc-map-table__cell"
            >
              <template v-if="column.key === 'equipment'">
                <router-link :to="{name: 'main', query: {id: item.id}}">
                  {{ item.name }}
                </router-link>
              </template>
              <template v-else-if="column.key === 'status'">
                <item-alert :item="item" mode="tooltip" right />
                <map-item :item="item" :icon-mode="{size: 50, width: 50, height: 50, lineWidth: 4}" />
                <div>
                  <div>
                    {{ $t('statusShort.' + getItemStatus(item).message) }}
                  </div>
                  <div>
                    <img :src="require('~/assets/icons/icon-angle.svg')" class="fc-map-table__icon -in-row">
                    {{ item.deviceStatus.currentAngle }}°
                  </div>
                </div>
              </template>
              <template v-else-if="column.key === 'speed'">
                <field-control v-model="instance[item.id].speed" :field="speedField" @change="syncSpeedDepthTime(instance[item.id], item, 'speed')" />
              </template>
              <template v-else-if="column.key === 'depth'">
                <field-control v-model="instance[item.id].depth" :field="depthField" @change="syncSpeedDepthTime(instance[item.id], item, 'depth')" />
              </template>
              <template v-else-if="column.key === 'time'">
                <field-control v-model="instance[item.id].time" :field="timeField" @change="syncSpeedDepthTime(instance[item.id], item, 'time')" />
              </template>
              <template v-else-if="column.key === 'serviceStop'">
                <field-control v-model="instance[item.id].serviceStop" :field="serviceStopField" />
              </template>
              <template v-else-if="column.key === 'management'">
                <item-icon-controls v-model="instance[item.id]" col-class="d-flex">
                  <v-col v-if="showSave(item)" class="d-flex flex-no-grow align-center">
                    <v-btn :loading="loading[item.id]" small @click="apply(instance[item.id], item)">{{ $t('actions.apply') }}</v-btn>
                    <v-btn :loading="loading[item.id]" small text @click="cancel(item)">{{ $t('actions.cancel') }}</v-btn>
                  </v-col>
                </item-icon-controls>
              </template>
              <template v-else-if="column.key === 'camera'">
                <v-btn v-if="item.snapshot && item.snapshot.big" depressed class="fc-image-btn pa-2" @click="openSnapshotById(item.id)">
                  <img
                    :src="item.snapshot.small"
                    class="fc-map-table__snapshot"
                  >
                </v-btn>
              </template>
              <template v-else-if="column.key === 'connection'">
                <item-online :item="item" />
              </template>
              <template v-else-if="column.key === 'last_update'">
                <div>
                  <div>{{ formatDate((item.deviceStatus.lastUpdate || {}).date || item.updated_at.date, 'D MMM') }}</div>
                  <div>{{ formatTimeFromDateTime((item.deviceStatus.lastUpdate || {}).date || item.updated_at.date, 'HH:mm') }}</div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </scrollable-table>
    </div>
    <v-dialog v-model="snapshot.opened" max-width="800">
      <v-card>
        <v-card-title>
          {{ snapshot.title }}
        </v-card-title>
        <v-card-text>
          <img :src="snapshot.big" :style="{maxWidth: '100%'}">
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :loading="snapshot.loading" text @click="refreshSnapshot">{{ $t('actions.refresh') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MapItem from '~/components/map/MapItem'
import ItemIconControls from '~/components/map/ItemIconControls'
import ItemAlert from '~/components/map/ItemAlert'
import ItemOnline from '~/components/map/ItemOnline'
import ScrollableTable from '~/components/utils/ScrollableTable'
import dateTimeFormat from '~/components/mixins/dateTimeFormat'
import generalFormMixin from '~/components/mixins/generalFormMixin'
import {sortByStr} from '~/utils/time'
import configFields from '~/config/fields'
import configControls from '~/config/controls'
import {getItemStatus} from '~/utils/status'

export default {
  components: {MapItem, ItemIconControls, ItemAlert, ItemOnline, ScrollableTable},
  mixins: [dateTimeFormat, generalFormMixin],
  data () {
    return {
      columns: [
        {key: 'equipment', flex: '0 0 80px'},
        {key: 'status', flex: '0 0 180px'},
        {key: 'speed', flex: '0 0 100px'},
        {key: 'depth', flex: '0 0 100px'},
        {key: 'time', flex: '0 0 100px'},
        {key: 'serviceStop', flex: '0 0 100px'},
        {key: 'management', flex: '1 0 250px', skipSort: true},
        {key: 'camera', flex: '0 0 120px'},
        {key: 'connection', flex: '0 0 70px'},
        {key: 'last_update', flex: '0 0 90px'}
      ],
      speedField: {
        ...configFields.item.find(field => field.key === 'deviceSettings.workPlan.speed'),
        label: ''
      },
      depthField: {
        ...configFields.item.find(field => field.key === 'deviceSettings.workPlan.depth'),
        label: ''
      },
      timeField: {
        ...configFields.item.find(field => field.key === 'deviceSettings.workPlan.time'),
        label: ''
      },
      serviceStopField: {
        ...configFields.item.find(field => field.key === 'deviceSettings.workPlan.serviceStop'),
        label: ''
      },
      instance: {},
      snapshot: {
        opened: false,
        loading: false
      }
    }
  },
  computed: {
    isReadOnly () { return this.$store.getters['auth/isReadOnly'] },
    controls () { return configControls },
    deviceStatus () { return this.item.deviceStatus || {} },
    items () { return this.$store.getters['rtu/items'] },
    instanceTrigger () { return JSON.stringify(this.items.map(item => ({id: item.id, ...item.deviceSettings.workPlan}))) },
    order () { return this.$route.query.order || 'equipment' },
    sortedItems () {
      return [...this.items].sort((item1, item2) => {
        const orderKey = this.order.replace(/-desc$/, '')
        const orderRev = /-desc$/.test(this.order) ? -1 : 1
        if (orderKey === 'equipment') {
          return orderRev * sortByStr(item1.name, item2.name)
        }
        if (orderKey === 'status') {
          return orderRev * sortByStr(item1.deviceStatus.move, item2.deviceStatus.move)
        }
        if (orderKey === 'speed') {
          return orderRev * (item1.deviceSettings.workPlan.speed - item2.deviceSettings.workPlan.speed)
        }
        if (orderKey === 'depth') {
          return orderRev * (item1.deviceSettings.workPlan.depth - item2.deviceSettings.workPlan.depth)
        }
        if (orderKey === 'serviceStop') {
          return orderRev * (item1.deviceSettings.workPlan.serviceStop - item2.deviceSettings.workPlan.serviceStop)
        }
        if (orderKey === 'camera') {
          return orderRev * ((item1.snapshot && item1.snapshot.big ? 1 : 0) - (item2.snapshot && item2.snapshot.big ? 1 : 0))
        }
        if (orderKey === 'connection') {
          return orderRev * ((item1.deviceStatus.online ? 1 : 0) - (item2.deviceStatus.online ? 1 : 0))
        }
        if (orderKey === 'last_update') {
          return orderRev * (moment(item1.updated_at.date).isBefore(item2.updated_at.date) ? -1 : 1)
        }
      })
    }
  },
  watch: {
    instanceTrigger: {
      immediate: true,
      handler () {
        const instance = {}
        const loading = {}
        const restoreValue = (ar1, ar2, key) => {
          if (_.isObject(ar1) && _.isObject(ar2)) { return ar1[key] || ar2[key] }
          if (_.isObject(ar1)) { return ar1[key] }
          if (_.isObject(ar2)) { return ar2[key] }
        }
        this.items.forEach((item) => {
          instance[item.id] = this.controls.reduce((out, control) => {
            out[control.key] = restoreValue(this.instance[item.id], item.deviceSettings.workPlan, control.key)
            return out
          }, {})
          const fullCircleTime = item.deviceSettings.fullCircleTime / 60
          instance[item.id].speed = restoreValue(this.instance[item.id], item.deviceSettings.workPlan, 'speed')
          instance[item.id].depth = restoreValue(this.instance[item.id], item.deviceSettings.workPlan, 'depth')
          instance[item.id].time = Math.floor(fullCircleTime / (instance[item.id].speed || 1) * 10000) / 100
          instance[item.id].serviceStop = restoreValue(this.instance[item.id], item.deviceSettings.workPlan, 'serviceStop')
          loading[item.id] = !!this.loading[item.id]
        })
        this.instance = instance
        this.loading = loading
      }
    }
  },
  beforeDestroy () {
    this._timeout && clearTimeout(this._timeout)
  },
  methods: {
    getItemStatus,
    showSave (item) {
      return !!Object.keys(this.instance[item.id]).find((key) => {
        return key !== 'time' && this.instance[item.id][key] !== item.deviceSettings.workPlan[key]
      })
    },
    cancel (item) {
      const instance = this.controls.reduce((out, control) => {
        out[control.key] = item.deviceSettings.workPlan[control.key]
        return out
      }, {})
      const fullCircleTime = item.deviceSettings.fullCircleTime / 60
      instance.speed = item.deviceSettings.workPlan.speed
      instance.depth = item.deviceSettings.workPlan.depth
      instance.serviceStop = item.deviceSettings.workPlan.serviceStop
      instance.time = Math.floor(fullCircleTime / (instance.speed || 1) * 10000) / 100
      this.instance[item.id] = instance
    },
    syncSpeedDepthTime (instance, item, source) {
      const k = item.deviceStatus.depth_k
      const fullCircleTime = item.deviceSettings.fullCircleTime / 60
      if (source === 'speed') {
        instance.depth = Math.floor(k / (instance.speed || 1) * 10000) / 100
        instance.time = Math.floor(fullCircleTime / (instance.speed || 1) * 10000) / 100
      } else if (source === 'depth') {
        instance.speed = Math.floor(k / (instance.depth || 1) * 10000) / 100
        instance.time = Math.floor(fullCircleTime / (instance.speed || 1) * 10000) / 100
      } else if (source === 'time') {
        instance.speed = Math.floor(fullCircleTime / (instance.time || 1) * 10000) / 100
        instance.depth = Math.floor(k / (instance.speed || 1) * 10000) / 100
      }
    },
    prepareCommand (data) {
      const command = {}
      command.move = data.move
      command.speed = parseFloat(data.speed).toFixed(1)
      command.depth = parseFloat(data.depth).toFixed(1)
      command.serviceStop = parseFloat(data.serviceStop).toFixed(1)
      command.irrigation = data.irrigation === 'off' ? 'stop' : 'start'
      command.acc = data.acc === 'off' ? 'stop' : 'start'
      command.autorev = data.autoRev === 'off' ? 'stop' : 'start'
      command.autostart = data.autoStart === 'off' ? 'stop' : 'start'
      return command
    },
    apply (instance, item) {
      this.loading[item.id] = true
      const command = this.prepareCommand(instance)
      command.id = item.id
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.cmd', data: command}))
        .then((response) => {
          if (response.data && response.data.error) {
            throw new Error(response.data.error)
          }
        })
        .then(() => new Promise((resolve) => {
          this._timeout && clearTimeout(this._timeout)
          this._timeout = setTimeout(resolve, 2000)
        }))
        .then(() => this.$store.dispatch('rtu/fetch'))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t('success.cmdSent')})
        })
        .catch(this.handleError)
        .finally(() => {
          this.loading[item.id] = false
        })
    },
    openSnapshotById (id) {
      const item = this.sortedItems.find(item => item.id === id)
      if (item) {
        this.snapshot = {
          id: item.id,
          ...item.snapshot,
          title: item.name,
          loading: false,
          opened: true
        }
      }
    },
    refreshSnapshot () {
      this.snapshot.loading = true
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.updateSnapshot', data: {id: this.snapshot.id}}))
        .then((response) => {
          if (response.data.snapshot === 'error') {
            throw new Error(this.$t('errors.updateSnapshot'))
          } else if (response.data.snapshot === 'ok') {
            return this.$store.dispatch('rtu/updateRandomToken')
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.openSnapshotById(this.snapshot.id)
        })
    }
  }
}
</script>
