<template>
  <highcharts v-if="showChart" :options="options" />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => []
    }
  },
  computed: {
    showChart () {
      return this.data['Wind speed'] && !_.isEmpty(this.data['Wind speed'])
    },
    options () {
      if (!this.showChart) {
        return null
      }
      return {
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 250,
        },
        credits: {
          enabled: false
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories: this.data.dates,
          // tickInterval: 4,

          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          tickInterval: 0.2,
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.$t('charts.wind_speed') + ', ' + this.$t('charts.units.ms'),
            margin: 15
          },
          labels: {
            formatter: function () {
              return this.value + ' мс';
            }
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
            pointWidth: 0,
            marker: {
              symbol: 'circle',
              enabled: false
            },
          },
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            return this.y
          }
        },
        legend: {
          enabled: false
        },
        series: [{
          yAxis: 0,
          zIndex: 2,
          events:{
            legendItemClick: function(){
              return false;
            }
          },
          data: this.data['Wind speed'],
          type: 'line',
          lineColor: 'rgb(46, 125, 50)',
          color: 'rgb(46, 125, 50)',
          lineWidth: 1,
          marker: {
            symbol: 'circle',
            enabled: true
          }
        }]
      }
    }
  }
}
</script>
