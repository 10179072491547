<script>
import geometryUtil from 'leaflet-geometryutil'
import {svgOverlay, marker, icon, circle, divIcon} from 'leaflet'
import {shadeBlend} from '~/utils/colors'
import {getItemStatus} from '~/utils/status'

export default {
  props: ['item', 'map', 'zoom', 'selected', 'editing', 'iconMode', 'onlyAreaPlanSegments'],
  data () {
    return {
      boxCenter: [100, 100],
      boxScale: 100,
      mapObject: null,
      selectedArrowObject1: null,
      selectedArrowObject2: null,
      dotObject1: null,
      dotObject2: null,
      overed: false,
      markerOvered: false,
      editingObjects: {},
      hoseStopLabels: {}
    }
  },
  computed: {
    displaySettings () { return this.item.displaySettings || {} },
    type () { return this.displaySettings.type || 'circle' },
    deviceSettings () { return this.item.deviceSettings || {} },
    deviceStatus () { return this.item.deviceStatus || {} },
    endGuns () { return this.deviceSettings.endGuns || [] },
    workPlan () { return this.deviceSettings.workPlan || {} },
    areaPlans () { return this.deviceSettings.areaPlans || {} },
    editingCoords () { return this.$store.state.rtu.editingCoords },
    lateralStart() {
      if (this.editing && this.editingCoords && this.editingCoords.lateralStart) {
        return this.editingCoords.lateralStart
      }
      if (this.displaySettings.path_coords) {
        return {
          lat: this.displaySettings.path_coords.start_lat,
          lng: this.displaySettings.path_coords.start_lng
        }
      } else {
        return null
      }
    },
    lateralEnd() {
      if (this.editing && this.editingCoords && this.editingCoords.lateralEnd) {
        return this.editingCoords.lateralEnd
      }
      if (this.displaySettings.path_coords) {
        return {
          lat: this.displaySettings.path_coords.end_lat,
          lng: this.displaySettings.path_coords.end_lng
        }
      } else {
        return null
      }
    },
    lateralHeight () {
      if (this.editing && this.editingCoords) {
        return Number(this.editingCoords.lateralHeight) || 0
      }
      return Number(this.displaySettings.height) || 0
    },
    lateralLength () {
      // Should be used for relative calc only: (startPosition, endPosition, currentPosition / lateralLength) * 100%
      if (this.editing && this.editingCoords) {
        return Number(this.editingCoords.lateralLength) || 0
      }
      return Number(this.displaySettings.length) || 0
    },
    lateralStartPosition () {
      if (this.editing && this.editingCoords) {
        return Number(this.editingCoords.lateralStartPosition) || 0
      }
      return Number(this.displaySettings.startPosition) || 0      
    },
    lateralEndPosition () {
      if (this.editing && this.editingCoords) {
        return Number(this.editingCoords.lateralEndPosition) || 0
      }
      return Number(this.displaySettings.endPosition) || 0      
    },
    lateralLengthPx () {
      return geometryUtil.distance(this.map, this.lateralStart, this.lateralEnd)
    },
    center () {
      if (this.type === 'lateral' && this.map) {
        return geometryUtil.interpolateOnLine(this.map, [this.lateralStart, this.lateralEnd], 0.5)?.latLng
      } else {
        return (this.editing && this.editingCoords && this.editingCoords.center) || this.displaySettings.center
      }
    },
    circleStartAngle () {
      if (this.editing && this.editingCoords) {
        return Number(this.editingCoords.circleStartAngle) || 0
      }
      return Number(this.displaySettings.startAngle) || 0
    },
    circleEndAngle () {
      if (this.editing && this.editingCoords) {
        return Number(this.editingCoords.circleEndAngle) || 0
      }
      return Number(this.displaySettings.endAngle) || 0
    },
    circlePrimaryRadius () {
      if (this.editing && this.editingCoords) {
        return Number(this.editingCoords.circlePrimaryRadius) || 0
      }
      return Number(this.displaySettings.radius) || 0
    },
    endGunMaxDistance () {
      return this.endGuns.length ? Math.max(...this.endGuns.map(endGun => endGun.distance || 0)) : 0
    },
    circleOverallRadius () { 
      return this.circlePrimaryRadius + this.endGunMaxDistance
    },
    bounds () {
      const bounds = {}
      if (this.type === 'lateral') {
        const box = []
        const angle = geometryUtil.bearing(this.lateralStart, this.lateralEnd)
        box.push(geometryUtil.destination(this.lateralStart, angle + 90, this.lateralHeight / 2 + this.endGunMaxDistance))
        box.push(geometryUtil.destination(this.lateralStart, angle - 90, this.lateralHeight / 2 + this.endGunMaxDistance))
        box.push(geometryUtil.destination(this.lateralEnd, angle + 90, this.lateralHeight / 2 + this.endGunMaxDistance))
        box.push(geometryUtil.destination(this.lateralEnd, angle - 90, this.lateralHeight / 2 + this.endGunMaxDistance))
        bounds.n = Math.max(...box.map(ll => ll.lat))
        bounds.s = Math.min(...box.map(ll => ll.lat))
        bounds.w = Math.min(...box.map(ll => ll.lng))
        bounds.e = Math.max(...box.map(ll => ll.lng))
      } else {
        bounds.n = geometryUtil.destination(this.center, 0, this.circleOverallRadius).lat
        bounds.s = geometryUtil.destination(this.center, 180, this.circleOverallRadius).lat
        bounds.w = geometryUtil.destination(this.center, -90, this.circleOverallRadius).lng
        bounds.e = geometryUtil.destination(this.center, 90, this.circleOverallRadius).lng
      }
      return [[bounds.n, bounds.w], [bounds.s, bounds.e]]
    },
    pointSize () {
      if (this.iconMode) { return this.iconMode.lineWidth || 1 }
      // Recalc code if zoom changes;
      // this.zoom
      return this.boxScale / Math.max(1, geometryUtil.distance(this.map, this.center, {lat: this.center.lat, lng: this.bounds[0][1]}))
    },
    borderSize () {
      return this.pointSize * 2
    },
    boxWidth () {
      if (this.type === 'lateral') {
        if (this.iconMode) {
          return this.iconMode.size
        } else if (this.map) {
          return geometryUtil.distance(this.map, {lat: this.bounds[0][0], lng: this.bounds[0][1]}, {lat: this.bounds[0][0], lng: this.bounds[1][1]})
        } else {
          return null
        }
      } else {
        return this.boxScale * 2 + this.borderSize * 2
      }
    },
    boxHeight () {
      if (this.type === 'lateral') {
        if (this.iconMode) {
          return this.iconMode.size
        } else if (this.map) {
          return geometryUtil.distance(this.map, {lat: this.bounds[0][0], lng: this.bounds[0][1]}, {lat: this.bounds[1][0], lng: this.bounds[0][1]})
        } else return null
      } else {
        return this.boxScale * 2 + this.borderSize * 2
      }
    },
    box () {
      return [0, 0, this.boxWidth, this.boxHeight].join(' ')
    },
    code () {
      return this.type === 'lateral' ? (this.iconMode ? this.lateralCodeIcon : this.lateralCode) : this.circleCode
    },
    itemColor () {
      const colors = {
        error: '#ff0000',
        warning: '#00b0f0',
        stop: '#dddddd',
        acc: '#ed7d31',
        irrigation: '#00b0f0',
        move: '#92d050'
      }
      const itemStatus = getItemStatus(this.item)
      let color = colors[itemStatus.color] || '#000000'
      if (this.overed || this.markerOvered || this.selected) {
        color = shadeBlend(0.2, color)
      }
      return color
    },
    itemErrorIcon () {
      const icons = {
        no_connection: 'icon-connection-off-2.svg',
        prsr_low: 'no-water-svgrepo-com.svg',
        alg_err: 'icon-repare.svg',
      }
      const itemStatus = getItemStatus(this.item)
      let icon = icons[itemStatus.message] || null
      let message = itemStatus.message
      
      return {icon, message}
    },
    lateralCodeIcon () {
      let code = ''
      const highlight = this.overed || this.markerOvered || this.selected
      const defaultFillOpacity = highlight ? 0.6 : 0.4
      const defaultStrokeOpacity = highlight ? 0.9 : 0.8
      const fillClickable = this.iconMode ? '#ddd' : '#fff'
      const length = this.deviceSettings.length || 1
      const size = this.iconMode.size
      const width = size / 2
      const center1 = {x: size / 2, y: 0}
      const center2 = {x: size / 2, y: size}
      const currentPercentage = Math.max(0, Math.min(1, this.deviceStatus.currentPosition / length))
      const currentPoint = {x: size / 2, y: currentPercentage * size}

      const borderPercentage = Math.max(0, Math.min(1, this.lateralEndPosition / length))
      const borderPoint = {x: size / 2, y: borderPercentage * size}


      // Semitransparent rectangle covering the main irrigation area;
      const path = `
        M ${center1.x - width / 2} ${center1.y} 
        L ${center1.x + width / 2} ${center1.y} 
        L ${center2.x + width / 2} ${center2.y} 
        L ${center2.x - width / 2} ${center2.y} 
        Z`
      code += `<path class="clickable" d="${path}" fill="${fillClickable}" fill-opacity="${defaultFillOpacity}" stroke="${fillClickable}" stroke-opacity="${defaultStrokeOpacity}" stroke-width="3" />`

      // Filled area, this.deviceStatus.move=frv => green from start, move=rev => green from end, move=stop => red from start; 
      const move = this.deviceStatus.move
      let fillPath = ''
      if (move === 'rev') {
        if (_.isNumber(this.lateralEndPosition) && Number(this.lateralEndPosition) > 0 && Number(this.lateralEndPosition) > Number(this.deviceStatus.currentPosition)) {
          fillPath = `
            M ${borderPoint.x + width / 2} ${borderPoint.y}
            L ${borderPoint.x - width / 2} ${borderPoint.y}
            L ${currentPoint.x - width / 2} ${currentPoint.y}
            L ${currentPoint.x + width / 2} ${currentPoint.y}
            Z`
        } else {
          fillPath = `
            M ${center2.x + width / 2} ${center2.y}
            L ${center2.x - width / 2} ${center2.y}
            L ${currentPoint.x - width / 2} ${currentPoint.y}
            L ${currentPoint.x + width / 2} ${currentPoint.y}
            Z`
        }
        
      } else {
        if (_.isNumber(this.lateralEndPosition) && Number(this.lateralEndPosition) > 0 && Number(this.lateralEndPosition) < Number(this.deviceStatus.currentPosition)) {
          fillPath = `
            M ${borderPoint.x + width / 2} ${borderPoint.y}
            L ${borderPoint.x - width / 2} ${borderPoint.y}
            L ${currentPoint.x - width / 2} ${currentPoint.y}
            L ${currentPoint.x + width / 2} ${currentPoint.y}
            Z`
        } else {
          fillPath = `
            M ${center1.x + width / 2} ${center1.y}
            L ${center1.x - width / 2} ${center1.y}
            L ${currentPoint.x - width / 2} ${currentPoint.y}
            L ${currentPoint.x + width / 2} ${currentPoint.y}
            Z`  
        }
        
      }

      code += `<path d="${fillPath}" fill="${this.itemColor}" fill-opacity="1" stroke="none" />`

      // Black line showing the current position;
      const currentPath = `
        M ${currentPoint.x - width / 2} ${currentPoint.y}
        L ${currentPoint.x + width / 2} ${currentPoint.y}
        Z`
      code += `<path d="${currentPath}" stroke="#000" stroke-opacity="1" stroke-width="${size > 100 ? 5 : 1}" />`

      // Dashed line with deviceSettings.workPlan.serviceStop;
      const serviceStop = this.deviceStatus.serviceStop
      if (_.isNumber(serviceStop)) {
        const servicePercentage = Math.max(0, Math.min(1, serviceStop / length))
        const servicePath = `
          M ${center1.x - width / 2} ${servicePercentage * size}
          L ${center1.x + width / 2} ${servicePercentage * size}
          Z`
        code += `<path d="${servicePath}" stroke="#000" stroke-opacity="1" stroke-width="2" stroke-dasharray="5 10" />`
      }

      // Border line of field parts
      const lateralEndPosition = this.lateralEndPosition
      if (_.isNumber(lateralEndPosition) && lateralEndPosition > 0) {
        const lateralEndPositionPercentage = Math.max(0, Math.min(1, lateralEndPosition / length))
        const lateralEndPositionPath = `
          M ${center1.x - width / 2} ${lateralEndPositionPercentage * size}
          L ${center1.x + width / 2} ${lateralEndPositionPercentage * size}
          Z`
        code += `<path d="${lateralEndPositionPath}" stroke="blue" stroke-opacity="1" stroke-width="2" />`
      }

      // Black triangle showing the direction
      if (move !== 'stop') {
        code += `<path d="${this.getArrowPath(size > 100 ? 10 : 5)}" fill="#000" fill-opacity="1" transform="translate(${size / 2.5}, ${currentPoint.y}) rotate(${180 * (move === 'rev' ? 1 : 0)})" />`
      }

      return code
    },
    lateralCode () {
      if (!this.map || !this.lateralStart || !this.lateralEnd) {
        return false
      }
      const px = (latlng, comma) => {
        const x = geometryUtil.distance(this.map, {lat: this.bounds[0][0], lng: this.bounds[0][1]}, {lat: this.bounds[0][0], lng: latlng.lng})
        const y = geometryUtil.distance(this.map, {lat: this.bounds[0][0], lng: this.bounds[0][1]}, {lat: latlng.lat, lng: this.bounds[0][1]})
        return `${x}${comma ? ',' : ' '}${y}`
      }
      let code = ''
      const highlight = this.overed || this.markerOvered || this.selected
      const defaultFillOpacity = highlight ? 0.6 : 0.4
      const defaultStrokeOpacity = highlight ? 0.9 : 0.8
      const fillClickable = this.iconMode ? '#ddd' : '#fff'
      const length = this.deviceSettings.length || 1
      const currentPercentage = Math.max(0, Math.min(1, this.deviceStatus.currentPosition / length))
      const currentPoint = geometryUtil.interpolateOnLine(this.map, [this.lateralStart, this.lateralEnd], currentPercentage)?.latLng
      
      const borderPercentage = Math.max(0, Math.min(1, this.lateralEndPosition / length))
      const borderPoint = geometryUtil.interpolateOnLine(this.map, [this.lateralStart, this.lateralEnd], borderPercentage)?.latLng

      const angle = this.iconMode ? 0 : geometryUtil.bearing(this.lateralStart, this.lateralEnd)
      const angleDot1 = geometryUtil.destination(this.lateralStart, angle + 90, this.lateralHeight / 2)
      const angleDot2 = geometryUtil.destination(this.lateralStart, angle - 90, this.lateralHeight / 2)
      const angleDot3 = geometryUtil.destination(this.lateralEnd, angle - 90, this.lateralHeight / 2)
      const angleDot4 = geometryUtil.destination(this.lateralEnd, angle + 90, this.lateralHeight / 2)
      const currentDot1 = geometryUtil.destination(currentPoint, angle - 90, this.lateralHeight / 2)
      const currentDot2 = geometryUtil.destination(currentPoint, angle + 90, this.lateralHeight / 2)
      const borderDot1 = geometryUtil.destination(borderPoint, angle + 90, this.lateralHeight / 2)
      const borderDot2 = geometryUtil.destination(borderPoint, angle - 90, this.lateralHeight / 2)
      const pxWidth = geometryUtil.distance(this.map, currentDot1, currentDot2)
      const trianglePoint = geometryUtil.destination(currentPoint, angle + 90, this.lateralHeight / 4)

      // Semitransparent rectangle covering the main irrigation area;
      const path = `M ${px(angleDot1)} L ${px(angleDot2)} L ${px(angleDot3)} L ${px(angleDot4)} Z`
      code += `<path class="clickable" d="${path}" fill="${fillClickable}" fill-opacity="${defaultFillOpacity}" stroke="${fillClickable}" stroke-opacity="${defaultStrokeOpacity}" stroke-width="3" />`

      // End guns covering area;
      if (this.endGuns && this.endGuns.length) {
        this.endGuns.forEach((endGun) => {
          if (endGun.status !== 'on') { return false }
          if (endGun.areas && endGun.areas.length) {
            const edge1 = endGun.side === 'left' ? angleDot2 : angleDot1
            const edge2 = endGun.side === 'left' ? angleDot3 : angleDot4
            endGun.areas.forEach((endGunArea) => {
              const areaDot1 = geometryUtil.interpolateOnLine(this.map, [edge1, edge2], Math.max(0, Math.min(1, endGunArea.start / length)))?.latLng
              const areaDot2 = geometryUtil.interpolateOnLine(this.map, [edge1, edge2], Math.max(0, Math.min(1, endGunArea.end / length)))?.latLng
              const areaDot3 = geometryUtil.destination(areaDot2, angle + 90 * (endGun.side === 'left' ? -1 : 1), endGun.distance)
              const areaDot4 = geometryUtil.destination(areaDot1, angle + 90 * (endGun.side === 'left' ? -1 : 1), endGun.distance)
              const path = `M ${px(areaDot1)} L ${px(areaDot2)} L ${px(areaDot3)} L ${px(areaDot4)} Z`
              code += `<path d="${path}" fill="#fff" fill-opacity="${defaultFillOpacity}" stroke="none" />`
            })
          }
        })
      }

      // Filled area, this.deviceStatus.move=frv => green from start, move=rev => green from end, move=stop => red from start; 
      const move = this.deviceStatus.move
      if (move === 'rev' && this.deviceStatus.currentPosition !== this.deviceSettings.length) {
        if (_.isNumber(this.lateralEndPosition) && Number(this.lateralEndPosition) > 0 && Number(this.lateralEndPosition) > Number(this.deviceStatus.currentPosition)) {
          const path = `M ${px(borderDot1)} L ${px(borderDot2)} L ${px(currentDot1)} L${px(currentDot2)} Z`
          code += `<path d="${path}" fill="${this.itemColor}" fill-opacity="1" stroke="none" />`  
        } else {
          const path = `M ${px(angleDot3)} L ${px(angleDot4)} L ${px(currentDot2)} L${px(currentDot1)} Z`
          code += `<path d="${path}" fill="${this.itemColor}" fill-opacity="1" stroke="none" />`
        }
      } else {
        if (_.isNumber(this.lateralEndPosition) && Number(this.lateralEndPosition) > 0 && Number(this.lateralEndPosition) < Number(this.deviceStatus.currentPosition)) {
          const path = `M ${px(borderDot1)} L ${px(borderDot2)} L ${px(currentDot1)} L${px(currentDot2)} Z`
          code += `<path d="${path}" fill="${this.itemColor}" fill-opacity="1" stroke="none" />`        
        } else {
          const path = `M ${px(angleDot1)} L ${px(angleDot2)} L ${px(currentDot1)} L${px(currentDot2)} Z`
          code += `<path d="${path}" fill="${this.itemColor}" fill-opacity="1" stroke="none" />`        
        }
      }
      // Black line from lateralStart to lateralEnd;
      code += `<path d="M ${px(this.lateralStart)} L ${px(this.lateralEnd)} Z" stroke="#000" stroke-opacity="1" stroke-width="1" />`

      // Black line showing the current position;
      code += `<path d="M ${px(currentDot1)} L ${px(currentDot2)} Z" stroke="#000" stroke-opacity="1" stroke-width="5" />`

      // Dashed line with deviceSettings.workPlan.serviceStop;
      const serviceStop = this.deviceStatus.serviceStop
      if (_.isNumber(serviceStop)) {
        const serviceStopPoint = geometryUtil.interpolateOnLine(this.map, [this.lateralStart, this.lateralEnd], Math.max(0, Math.min(1, serviceStop / length)))?.latLng
        const stopDot1 = geometryUtil.destination(serviceStopPoint, angle + 90, this.lateralHeight / 2)
        const stopDot2 = geometryUtil.destination(serviceStopPoint, angle - 90, this.lateralHeight / 2)
        code += `<path d="M ${px(stopDot1)} L ${px(stopDot2)} Z" stroke="#000" stroke-opacity="1" stroke-width="2" stroke-dasharray="5 10" />`
      }

      // Border line of field parts
      const lateralEndPosition = this.lateralEndPosition
      if (_.isNumber(lateralEndPosition) && Number(lateralEndPosition) > 0) {
        const lateralEndPositionPoint = geometryUtil.interpolateOnLine(this.map, [this.lateralStart, this.lateralEnd], Math.max(0, Math.min(1, lateralEndPosition / length)))?.latLng
        const lateralEndPositionDot1 = geometryUtil.destination(lateralEndPositionPoint, angle + 90, this.lateralHeight / 2)
        const lateralEndPositionDot2 = geometryUtil.destination(lateralEndPositionPoint, angle - 90, this.lateralHeight / 2)
        code += `<path d="M ${px(lateralEndPositionDot1)} L ${px(lateralEndPositionDot2)} Z" stroke="blue" stroke-opacity="1" stroke-width="3"/>`
      }

      // HoseStops;
      if (this.deviceSettings.hoseStops && this.deviceSettings.hoseStops.areas.length) {
        this.deviceSettings.hoseStops.areas.forEach((stop, i) => {
          const hoseStopPoint = geometryUtil.interpolateOnLine(this.map, [this.lateralStart, this.lateralEnd], Math.max(0, Math.min(1, stop.position / length)))?.latLng
          const hoseStopDot = geometryUtil.destination(hoseStopPoint, this.deviceSettings.hoseStops.align === 'center' ? angle : this.deviceSettings.hoseStops.align === 'left' ? angle - 90 : angle + 90, this.deviceSettings.hoseStops.align === 'center' ? this.lateralHeight / 100 : this.lateralHeight  * 5 / 10)
          const coords = px(hoseStopDot).split(' ')
          const visibility = this.deviceSettings.hoseStops.visibility !== true ? 'hidden' : 'visible'
          code += `<circle cx="${coords[0]}" cy="${coords[1]}" r="5" fill="#f59e2f" fill-opacity="1" visibility="${visibility}" />`
          // code += `<g transform="translate(${coords[0]},${coords[1]})"><path stroke="#ff9300" fill="#fff" d="M1.8.5h19c1.29,0,1.29,0,1.29,1.3V12.59c0,1.3,0,1.3-1.29,1.3H13.46l-2.59,2.59-2.6-2.59H1.8c-1.3,0-1.3,0-1.3-1.3V1.8C.5.5.5.5,1.8.5"/><text transform="translate(7.41 11.23)">${i+1}</text></g>`          
        })
      }

      // Black triangle showing the direction
      if (move !== 'stop') {
        code += `<path d="${this.getArrowPath(Math.min(pxWidth / 8, 20))}" fill="#000" fill-opacity="1" transform="translate(${px(trianglePoint, true)}) rotate(${angle + 180 * (move === 'rev' ? 0 : 1)})" />`
      }
      return code
    },
    circleCode () {
      let code = ''
      if (!this.circleOverallRadius || this.pointSize === this.boxScale || !this.center) { return false }
      const r = this.boxScale * this.circlePrimaryRadius / this.circleOverallRadius
      const cx = this.boxScale + this.borderSize
      const cy = this.boxScale + this.borderSize
      const highlight = this.overed || this.markerOvered || this.selected
      const defaultFillOpacity = highlight ? 0.6 : 0.4
      const defaultStrokeOpacity = highlight ? 0.9 : 0.8
      const totalR = this.boxScale * (this.circlePrimaryRadius + this.endGunMaxDistance) / this.circleOverallRadius
      const fillClickable = this.iconMode ? '#ddd' : '#fff'
      const strokeClickable = this.iconMode ? '#999' : '#fff'
      const errorColor = '#FF0000'
      const errorColor2 = '#f39d9d'

      // Wrap for icon view
      // if (this.iconMode) {
      //   code += `<circle cx="${cx}" cy="${cy}" r="${totalR}" fill="#ccc" fill-opacity="${defaultFillOpacity}" />`
      // }

      // Semitransparent circle or pie covering the main irrigation area;
      // It's clickable;
      // It goes from the displaySettings.startAngle, endAngle values now;
      if (!this.onlyAreaPlanSegments) {
        if (_.isNumber(this.circleStartAngle) && _.isNumber(this.circleEndAngle) && (this.circleEndAngle - this.circleStartAngle) % 360) {
          const path = `M ${this.getArcPath(cx, cy, r, this.circleStartAngle, this.circleEndAngle, false)} L ${cx} ${cy} Z`
          code += `<path class="clickable" d="${path}" fill="${fillClickable}" fill-opacity="${defaultFillOpacity}" stroke="${fillClickable}" stroke-opacity="${defaultStrokeOpacity}" stroke-width="${this.borderSize}" />`
        } else {
          code += `<circle class="clickable" cx="${cx}" cy="${cy}" r="${r}" fill="${fillClickable}" fill-opacity="${defaultFillOpacity}" stroke="${fillClickable}" stroke-opacity="${defaultStrokeOpacity}" stroke-width="${this.borderSize}" />`
        }

        // End guns covering area;
        if (this.endGuns && this.endGuns.length) {
          this.endGuns.forEach((endGun) => {
            if (endGun.status !== 'on') { return false }
            const r2 = this.boxScale * (this.circlePrimaryRadius + endGun.distance) / this.circleOverallRadius
            if (endGun.areas && endGun.areas.length) {
              endGun.areas.forEach((endGunArea) => {
                const path = `M ${this.getArcPath(cx, cy, r, endGunArea.startAngle, endGunArea.endAngle)} L ${this.getArcPath(cx, cy, r2, endGunArea.endAngle, endGunArea.startAngle, 1)} Z`
                code += `<path d="${path}" fill="#fff" fill-opacity="${defaultFillOpacity}" stroke="none" />`
              })
            } else {
              const path1 = `M ${this.getArcPath(cx, cy, r, 0, 180)} L ${this.getArcPath(cx, cy, r2, 180, 0, 1)} Z`
              code += `<path d="${path1}" fill="#fff" fill-opacity="${defaultFillOpacity}" stroke="none" />`
              const path2 = `M ${this.getArcPath(cx, cy, r, 180, 360)} L ${this.getArcPath(cx, cy, r2, 360, 180, 1)} Z`
              code += `<path d="${path2}" fill="#fff" fill-opacity="${defaultFillOpacity}" stroke="none" />`
            }
          })
        }

        // Primary pie: from startAngle to deviceStatus.currentAngle filled with blue or red;
      
        if (_.isNumber(this.deviceStatus.currentAngle)) {
          let fill_direction = ((this.deviceStatus.move === 'stop' || this.deviceStatus.move === 'barrier_stop' || this.deviceStatus.move === 'service_stop' || this.deviceStatus.move === 'finish') && this.deviceStatus.arcDirection == 'rev') || this.deviceStatus.move === 'rev'
          // if (this.item.id === 254)
          // console.log(fill_direction, this.deviceStatus.move)
          const path = `M ${this.getArcPath(cx, cy, r, this.deviceStatus.startAngle, this.deviceStatus.currentAngle, fill_direction)} L ${cx} ${cy} Z`
          code += `<path d="${path}" fill="${this.itemColor}" fill-opacity="1" stroke="${strokeClickable}" stroke-opacity="1" stroke-width="${this.borderSize}" />`
        }
      } 
      else {
        if (_.isNumber(this.circleStartAngle) && _.isNumber(this.circleEndAngle) && (this.circleEndAngle - this.circleStartAngle) % 360) {
          const path = `M ${this.getArcPath(cx, cy, totalR, this.circleStartAngle, this.circleEndAngle, false)} L ${cx} ${cy} Z`
          code += `<path class="clickable" d="${path}" fill="${fillClickable}" fill-opacity="${defaultFillOpacity}" stroke="${fillClickable}" stroke-opacity="${defaultStrokeOpacity}" stroke-width="${this.borderSize}" />`
        } else {
          code += `<circle class="clickable" cx="${cx}" cy="${cy}" r="${totalR}" fill="${fillClickable}" fill-opacity="${defaultFillOpacity}" stroke="${fillClickable}" stroke-opacity="${defaultStrokeOpacity}" stroke-width="${this.borderSize}" />`
        }
      }

      // Primary black line with the current position;
      if (_.isNumber(this.deviceStatus.currentAngle)) {
        const coords = this.getArcCoords(cx, cy, this.deviceStatus.currentAngle, r - this.borderSize)
        code += `<line x1="${cx}" y1="${cy}" x2="${coords[0]}" y2="${coords[1]}" stroke="#000" stroke-width="${this.borderSize * 2}" />`
      }

      // Dashed line with deviceSettings.workPlan.serviceStop;
      if (_.isNumber(this.deviceStatus.serviceStop)) {
        const coords = this.getArcCoords(cx, cy, this.deviceStatus.serviceStop, r)
        code += `<line x1="${cx}" y1="${cy}" x2="${coords[0]}" y2="${coords[1]}" stroke="#000" stroke-width="${this.borderSize * 2}" stroke-dasharray="${this.borderSize * 4}" />`
      }

      // Arrow with the current move direction;
      if ((this.deviceStatus.move !== 'stop' && this.deviceStatus.move !== 'finish' && this.deviceStatus.move !== 'barrier_stop' && this.deviceStatus.move !== 'service_stop') && _.isNumber(this.deviceStatus.currentAngle)) {
        // const coords = this.getArcCoords(cx, cy, this.deviceStatus.currentAngle, r * (this.iconMode ? 0.5 : 0.9))
        // code += `<path d="${this.getArrowPath(this.borderSize)}" fill="#000" fill-opacity="1" stroke="#fff" stroke-opacity="1" stroke-width="${this.borderSize}" transform="translate(${coords[0]}, ${coords[1]}) rotate(${this.deviceStatus.currentAngle + 90 * (this.deviceStatus.move === 'rev' ? 1 : -1)})" />`
        const coords = this.getArcCoords(cx, cy, this.deviceStatus.currentAngle, Math.max(r * 5 / 6, r - this.borderSize * 7))
        code += `<path d="${this.getArrowPath(Math.min(this.borderSize * 6, r / 6))}" fill="#000" fill-opacity="1" transform="translate(${coords[0]}, ${coords[1]}) rotate(${this.deviceStatus.currentAngle + 90 * (this.deviceStatus.move === 'rev' ? 1 : -1)})" />`
      }

      // Wrap for icon view;
      if (this.iconMode) {
        code += `<circle cx="${cx}" cy="${cy}" r="${totalR}" fill="transparent" stroke="#000" stroke-opacity="0" stroke-width="${this.borderSize}" />`
        code += `<circle cx="${cx}" cy="${cy}" r="${this.borderSize * 2}" fill="#000" />`
      }

      // Red circumference for error;
      if (!this.onlyAreaPlanSegments) {
        if (this.deviceStatus.error) {
          if (_.isNumber(this.circleStartAngle) && _.isNumber(this.circleEndAngle) && (this.circleEndAngle - this.circleStartAngle) % 360) {
            const path = `M ${this.getArcPath(cx, cy, r, this.circleStartAngle, this.circleEndAngle, false)} L ${cx} ${cy} Z`
            code += `<path d="${path}" fill="transparent" stroke="${errorColor}" stroke-opacity="1" stroke-width="${this.borderSize}" />`
          } else {
            code += `<circle cx="${cx}" cy="${cy}" r="${r}" fill="transparent" stroke="${errorColor}" stroke-opacity="1" stroke-width="${this.borderSize}" />`
          }
          if (_.isNumber(this.deviceStatus.currentAngle)) {
            const path = `M ${this.getArcPath(cx, cy, r, this.deviceStatus.startAngle, this.deviceStatus.currentAngle, this.deviceStatus.move === 'rev')} L ${cx} ${cy} Z`
            code += `<path d="${path}" fill="${errorColor2}" fill-opacity="1" />`
          }
        } else {
          if (_.isNumber(this.circleStartAngle) && _.isNumber(this.circleEndAngle) && (this.circleEndAngle - this.circleStartAngle) % 360) {
            const path = `M ${this.getArcPath(cx, cy, r, this.circleStartAngle, this.circleEndAngle, false)} L ${cx} ${cy} Z`
            code += `<path d="${path}" fill="transparent" stroke="${this.itemColor}" stroke-opacity="1" stroke-width="${this.borderSize}" />`
          } else {
            code += `<circle cx="${cx}" cy="${cy}" r="${r}" fill="transparent" stroke="${this.itemColor}" stroke-opacity="1" stroke-width="${this.borderSize}" />`
          }
        } 
      }

      // AreaPlan
      if (this.onlyAreaPlanSegments) {
        if (this.areaPlans) {
          this.areaPlans.forEach((areaPlan) => {
            let planColor = '#92d050';
  
            if (areaPlan.irrigation == 'on') {
              planColor = '#00b0f0'
              
              if (areaPlan.acc == 'on') {
                planColor = '#ed7d31'
              }
            }
  
            // areaPlan.end - 0.01 для полной заливки сектора от 0 до 360 
            const path = `M ${this.getArcPath(cx, cy, r, areaPlan.start, areaPlan.end - 0.01, false)} L ${cx} ${cy} Z`
            code += `<path d="${path}" fill="${planColor}" fill-opacity="0.5" stroke="${strokeClickable}" stroke-opacity="0.3" />`
          })
        }
      }

      // HoseStops;
      if (this.deviceSettings.hoseStops && this.deviceSettings.hoseStops.areas.length) {
          this.deviceSettings.hoseStops.areas.forEach((stop, i) => {
          const visibility = this.deviceSettings.hoseStops.visibility !== true ? 'hidden' : 'visible'
          const coords = this.getArcCoords(cx, cy, stop.position, r)
          
          code += `<circle cx="${coords[0]}" cy="${coords[1]}" r="4" fill="#8A1FDF" fill-opacity="1" visibility="${visibility}" />`
          code += `<line x1="${cx}" y1="${cy}" x2="${coords[0]}" y2="${coords[1]}" stroke="#8A1FDF" stroke-width="${this.borderSize * 2}" stroke-dasharray="${this.borderSize * 4}" />`
        })
      }

      // Or red/blue repeating the pie color;
      return code
    },
    drawTrigger () {
      return JSON.stringify({bounds: this.bounds, code: this.code, box: this.box})
    },
    selectedTrigger () {
      return JSON.stringify({selected: this.selected, editing: this.editing, position: this.center, start: this.lateralStart, end: this.lateralEnd })
    },
    editingTrigger () {
      return JSON.stringify({selected: this.selected, editing: this.editing, editingCoords: this.editingCoords})
    },
    dotTrigger () {
      return JSON.stringify({position: this.center, lateralStart: this.lateralStart, lateralEnd: this.lateralEnd})
    },
    updateHoseStopLabelsTrigger () {
      return JSON.stringify({
        hoseStops: this.deviceSettings.hoseStops,
        hoseStopsPosition: this.deviceSettings.hoseStops,
        length: this.deviceSettings.length,
        lateralStart: this.lateralStart,
        lateralEnd: this.lateralEnd,
        lateralHeight: this.lateralHeight,
        iconMode: this.iconMode
      })
    }
  },
  watch: {
    drawTrigger: {
      immediate: true,
      handler () {
        if (this.mapObject) {
          this.mapObject.remove()
        }
        if (!this.code) { return false }
        if (!this.map) { return false }
        const svgElement = this.createSvgElement(this.code, this.box)
        this.mapObject = svgOverlay(svgElement, this.bounds, {zIndex: 1})
        this.mapObject.addTo(this.map)
        this.mapObject.bringToBack()
        svgElement.addEventListener('mouseover', () => {
          this.overed = true
        })
        svgElement.addEventListener('mouseout', () => {
          this.$nextTick(() => {
            this.overed = false
          })
        })
        svgElement.addEventListener('click', (e) => {
          e.stopPropagation()
          this.$emit('click')
        })
      }
    },
    selectedTrigger: {
      immediate: true,
      handler (value) {
        if (this.selectedArrowObject1) {
          this.selectedArrowObject1.remove()
        }
        if (this.selectedArrowObject2) {
          this.selectedArrowObject2.remove()
        }
        if (!this.selected || this.editing) { return false }
        if (!this.map) { return false }
        if (this.type === 'lateral') {
          if (this.lateralStart) {
           this.selectedArrowObject1 = marker(this.lateralStart, {zIndexOffset: 2, icon: icon({iconUrl: require('~/assets/icons/icon-arrow4.svg'), iconSize: 24, iconAnchor: [12, 24]})}).addTo(this.map)
          }
          if (this.lateralEnd) {
            this.selectedArrowObject2 = marker(this.lateralEnd, {zIndexOffset: 2, icon: icon({iconUrl: require('~/assets/icons/icon-arrow4.svg'), iconSize: 24, iconAnchor: [12, 24]})}).addTo(this.map)
          }
        } else {
          if (this.center) {
            this.selectedArrowObject1 = marker(this.center, {zIndexOffset: 2, icon: icon({iconUrl: require('~/assets/icons/icon-arrow4.svg'), iconSize: 24, iconAnchor: [12, 24]})}).addTo(this.map)
          }
        }
      }
    },
    dotTrigger: {
      immediate: true,
      handler () {
        if (this.dotObject1) {
          this.dotObject1.remove()
        }
        if (this.dotObject2) {
          this.dotObject2.remove()
        }
        if (!this.map) { return false }
        if (this.type === 'lateral') {
          if (this.lateralStart) {
            this.dotObject1 = marker(this.lateralStart, {zIndexOffset: 1, icon: icon({iconUrl: require('~/assets/icons/icon-dot.svg'), iconSize: 20, iconAnchor: [10, 10]})})
            this.dotObject1.addTo(this.map)
            this.dotObject1.on('click', () => {
              this.$emit('click')
            })
          }
          if (this.lateralEnd) {
            this.dotObject2 = marker(this.lateralEnd, {zIndexOffset: 1, icon: icon({iconUrl: require('~/assets/icons/icon-dot.svg'), iconSize: 20, iconAnchor: [10, 10]})})
            this.dotObject2.addTo(this.map)
            this.dotObject2.on('click', () => {
              this.$emit('click')
            })
          } 
          // if (this.itemErrorIcon) {
            // marker(this.center, {zIndexOffset: 1, icon: icon({iconUrl: require('~/assets/icons/' + this.itemErrorIcon.icon), iconSize: 30, iconAnchor: [20, 5]})})
            // .bindPopup(this.$t('status.' + this.itemErrorIcon.message))
            // .addTo(this.map)
          // }
        } else {
          if (this.center) { 
            this.dotObject1 = marker(this.center, {zIndexOffset: 1, icon: icon({iconUrl: require('~/assets/icons/icon-dot.svg'), iconSize: 20, iconAnchor: [10, 10]})})
            this.dotObject1.addTo(this.map)
            this.dotObject1.on('click', () => {
              this.$emit('click')
            })

            // if (this.itemErrorIcon) {
            //   marker(this.center, {zIndexOffset: 1, icon: icon({iconUrl: require('~/assets/icons/' + this.itemErrorIcon.icon), iconSize: 30, iconAnchor: [-10, -10]})})
            //   .bindPopup(this.$t('status.' + this.itemErrorIcon.message))
            //   .addTo(this.map)
            // }
          }
        }
      }
    },
    editing (value) {
      if (value) {
        if (this.type === 'lateral') {
          this.$store.dispatch('rtu/setEditingCoords', {
            lateralStart: {
              lat: this.displaySettings.path_coords.start_lat,
              lng: this.displaySettings.path_coords.start_lng
            },
            lateralEnd: {
              lat: this.displaySettings.path_coords.end_lat,
              lng: this.displaySettings.path_coords.end_lng
            },
            lateralHeight: this.deviceSettings.height,
            lateralLength: this.displaySettings.length,
            lateralStartPosition: this.displaySettings.startPosition,
            lateralEndPosition: this.displaySettings.endPosition
          })
        } else {
          this.$store.dispatch('rtu/setEditingCoords', {
            center: {...this.displaySettings.center},
            circlePrimaryRadius: this.displaySettings.radius,
            circleStartAngle: this.displaySettings.startAngle,
            circleEndAngle: this.displaySettings.endAngle,
            angle: 45
          })
        }
      } else {
        this.$store.dispatch('rtu/setEditingCoords', null)
      }
    },
    editingTrigger () {
      if (!this.editing || !this.selected || !this.editingCoords) {
        this.removeEditingObjects()
        return false
      }
      if (!this.map) { return false }
      const coords = this.editingCoords
      if (this.type === 'lateral') {
        if (!this.editingObjects.start) {
          this.editingObjects.start = marker(coords.lateralStart, {draggable: true, zIndexOffset: 4, icon: icon({iconUrl: require('~/assets/icons/icon-edit-dot.svg'), iconSize: 20, iconAnchor: [10, 10]})}).addTo(this.map)
          this.editingObjects.start.on('drag', (e) => {
            this.$store.dispatch('rtu/updateEditingCoords', {lateralStart: {...e.latlng}})
          })
        } else {
          this.editingObjects.start.setLatLng(coords.lateralStart)
        }
        if (!this.editingObjects.end) {
          this.editingObjects.end = marker(coords.lateralEnd, {draggable: true, zIndexOffset: 4, icon: icon({iconUrl: require('~/assets/icons/icon-edit-dot.svg'), iconSize: 20, iconAnchor: [10, 10]})}).addTo(this.map)
          this.editingObjects.end.on('drag', (e) => {
            this.$store.dispatch('rtu/updateEditingCoords', {lateralEnd: {...e.latlng}})
          })
        } else {
          this.editingObjects.end.setLatLng(coords.lateralEnd)
        }
      } else {
        const center = coords.center
        const radius = Number(coords.circlePrimaryRadius) || 0
        const angle = Number(coords.angle) || 0
        const dotCoords = geometryUtil.destination(center, angle, radius)
        if (!this.editingObjects.center) {
          this.editingObjects.center = marker(center, {draggable: true, zIndexOffset: 4, icon: icon({iconUrl: require('~/assets/icons/icon-edit-dot.svg'), iconSize: 20, iconAnchor: [10, 10]})}).addTo(this.map)
          this.editingObjects.center.on('drag', (e) => {
            this.$store.dispatch('rtu/updateEditingCoords', {center: {...e.latlng}})
          })
        } else {
          this.editingObjects.center.setLatLng(center)
        }
        if (!this.editingObjects.dot) {
          this.editingObjects.dot = marker(dotCoords, {draggable: true, zIndexOffset: 4, icon: icon({iconUrl: require('~/assets/icons/icon-edit-dot.svg'), iconSize: 20, iconAnchor: [10, 10]})}).addTo(this.map)
          this.editingObjects.dot.on('drag', (e) => {
            this.$store.dispatch('rtu/updateEditingCoords', {
              angle: geometryUtil.bearing(this.editingCoords.center, e.latlng),
              circlePrimaryRadius: e.latlng.distanceTo(this.editingCoords.center)
            })
          })
        } else {
          this.editingObjects.dot.setLatLng(dotCoords)
        }
        if (!this.editingObjects.border) {
          this.editingObjects.border = circle(center, {zIndex: 10, zIndexOffset: 10, radius}).addTo(this.map)
        } else {
          this.editingObjects.border.setLatLng(center).setRadius(radius)
        }
      }
    },
    updateHoseStopLabelsTrigger: {
      immediate: true,
      handler () {
        if (this.iconMode) {
          return false
        }
        const newHoseStops = {}
        if (this.deviceSettings.hoseStops && this.deviceSettings.hoseStops.areas.length) {
          if (this.lateralStart && this.lateralEnd) {
            this.deviceSettings.hoseStops.areas.forEach((stop, i) => {
              const angle = geometryUtil.bearing(this.lateralStart, this.lateralEnd)
              const hoseStopPoint = geometryUtil.interpolateOnLine(this.map, [this.lateralStart, this.lateralEnd], Math.max(0, Math.min(1, stop.position / (this.deviceSettings.length || 1))))?.latLng
              const hoseStopDot = geometryUtil.destination(hoseStopPoint, this.deviceSettings.hoseStops.align === 'left' ? angle - 90 : angle + 90, this.deviceSettings.hoseStops.align === 'center' ? 0 : this.lateralHeight  * 5 / 10)
              const key = `${hoseStopDot.lat}-${hoseStopDot.lng}-${i}`
              
              if (!this.hoseStopLabels[key]) {
                newHoseStops[key] = marker(hoseStopDot, {icon: divIcon({iconSize: [20, 20]}), opacity: 0}).addTo(this.map)
                newHoseStops[key].bindTooltip(`${stop.num}`, {direction: 'top', className: 'labelstyle'})
                newHoseStops[key].on('mouseover', () => {
                  this._overTimeout && clearTimeout(this._overTimeout) 
                  this.markerOvered = true
                })
                newHoseStops[key].on('mouseout', () => {
                  this._overTimeout = setTimeout(() => {
                    this.markerOvered = false
                  }, 100)
                })
              }
            })
          }
        }
        Object.keys(this.hoseStopLabels).forEach((key) => {
          if (!newHoseStops[key]) {
            this.hoseStopLabels[key].remove()
          }
        })
        this.hoseStopLabels = newHoseStops
      }
    }
  },
  render (createElement) {
    if (this.map) {
      return null
    }
    return createElement('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: this.box,
        width: this.iconMode.width,
        height: this.iconMode.height
      },
      domProps: {
        innerHTML: this.code
      }
    })
  },
  beforeDestroy () {
    if (this.mapObject) {
      this.mapObject.remove()
    }
    if (this.dotObject1) {
      this.dotObject1.remove()
    }
    if (this.dotObject2) {
      this.dotObject2.remove()
    }
    if (this.selectedArrowObject1) {
      this.selectedArrowObject1.remove()
    }
    if (this.selectedArrowObject2) {
      this.selectedArrowObject2.remove()
    }
    this.removeEditingObjects()
    this._overTimeout && clearTimeout(this._overTimeout) 
  },
  methods: {
    getArcCoords (x, y, angle, distance) {
      return [x + Math.sin(Math.PI * angle / 180) * distance, y - Math.cos(Math.PI * angle / 180) * distance]
    },
    getArcPath (cx, cy, r, startAngle, endAngle, rev) {
      startAngle = startAngle % 360
      if (startAngle < 0) startAngle += 360
      endAngle = endAngle % 360
      if (endAngle < 0) endAngle += 360
      const arcStart = this.getArcCoords(cx, cy, startAngle || 0, r)
      const arcEnd = this.getArcCoords(cx, cy, endAngle || 0, r)
      let largeArc = 0
      let sweepFlag = 0
      if (endAngle > startAngle) {
        if (endAngle - startAngle > 180 && !rev) {
          largeArc = 1
          sweepFlag = 1
        } else if (endAngle - startAngle > 180 && rev) {
          largeArc = 0
          sweepFlag = 0
        } else if (endAngle - startAngle <= 180 && !rev) {
          largeArc = 0
          sweepFlag = 1
        } else if (endAngle - startAngle <= 180 && rev) {
          largeArc = 1
          sweepFlag = 0
        }
      } else {
        if (startAngle - endAngle > 180 && rev) {
          largeArc = 1
          sweepFlag = 0
        } else if (startAngle - endAngle > 180 && !rev) {
          largeArc = 0
          sweepFlag = 1
        } else if (startAngle - endAngle <= 180 && !rev) {
          largeArc = 1
          sweepFlag = 1
        } else if (startAngle - endAngle <= 180 && rev) {
          largeArc = 0
          sweepFlag = 0
        }
      }
      return `${arcStart[0]} ${arcStart[1]} A ${r} ${r} 0 ${largeArc} ${sweepFlag} ${arcEnd[0]} ${arcEnd[1]}`
    },
    getArrowPath (s) {
      return `M ${s} 0 L 0 ${s * 2} L -${s} 0 Z`
    },
    /*
    getColorByDeviceStatus(status, highlight) {
      const colors = [
        {acc: 'on', color: '#ffba87'},
        {move: ['frw', 'rev'], irrigation: 'off', color: '#bfe5a5'},
        {move: ['frw', 'rev'], irrigation: 'on', color: '#7dd1fd'},
        {move: 'stop', color: '#d2d2d2'},
        {move: 'finish', color: '#d2d2d2'}
      ]
      const entry = colors.find((entry) => {
        const unmatchingProp = Object.keys(entry).find((key) => {
          if (key === 'color') { return false }
          if (_.isArray(entry[key])) {
            return !entry[key].includes(status[key])
          } else {
            return entry[key] !== status[key]
          }
        })
        return !unmatchingProp
      })
      const color = entry ? entry.color : '#000'
      return highlight ? shadeBlend(0.2, color) : color
    },
    */
    createSvgElement (code, box) {
      const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
      svgElement.setAttribute('viewBox', box)
      svgElement.setAttribute('class', "pivot")
      svgElement.innerHTML = code
      return svgElement
    },
    removeEditingObjects () {
      Object.values(this.editingObjects).forEach((obj) => {
        obj.remove()
      })
      this.editingObjects = {}
    }
  }
}
</script>

