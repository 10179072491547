<template>
  <div class="fc-map__page elevation-1">
    <h1 class="title">Defaut Page placeholder goes here</h1>
    <div class="body-1">Page path: {{ $route.path }}</div>
    <div class="body-1">The current language is: {{ serverSettings.locale }}</div>
    <div class="body-1">The current time offset is: {{ serverSettings.timeOffset }}</div>
    <div class="body-1">The current user time is: {{ currentDateTime }}</div>
    <div class="body-1">The current local machine time is: {{ localMachineDateTime }}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentDateTime: null,
      localMachineDateTime: null
    }
  },
  computed: {
    serverSettings () { return this.$store.state.ux.serverSettings }
  },
  created () {
    this.runDateTime()
  },
  beforeDestroy () {
    this._timeout && clearTimeout(this._timeut)
  },
  methods: {
    runDateTime () {
      this.currentDateTime = moment().utcOffset(this.serverSettings.timeOffset).format("dddd, MMMM Do YYYY, HH:mm:ss")
      this.localMachineDateTime = moment().format("dddd, MMMM Do YYYY, HH:mm:ss")
      this._timeout = setTimeout(this.runDateTime, 1000)
    }
  }
}
</script>
