<template>
  <div>
    <alert :msg.sync="alert" />
    <v-data-table
      :headers="headers"
      :items="items"
      :no-data-text="$t(`admin.${mode}.noDataText`)"
      :loading="loading === mode"
    >
      <template v-slot:item="{item}">
        <tr class="clickable" @click="selectItem(item)">
          <td v-for="header in headers" :key="header.value">
            <template v-if="header.format === 'email'">
              <a v-if="getItemValue(item, header)" :href="'mailto:' + getItemValue(item, header)">
                {{ getItemValue(item, header) }}
              </a>
            </template>
            <template v-else-if="header.format === 'devices'">
              {{ (getItemValue(item, header) || []).map(device => device.name).join(', ') }}
            </template>
            <template v-else-if="header.format === 'controls'">
              <div class="d-flex align-center justify-end">
                <v-btn icon @click="selectItem(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-menu left offset-y>
                  <template v-slot:activator="{on}">
                    <v-btn :loading="loading === 'delete-' + item.id" icon v-on="on">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-title>
                        {{ $t('actions.delete') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click.prevent>
                      <v-list-item-title>
                        {{ $t('actions.cancel') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
            <template v-else>
              {{ getItemValue(item, header) }}
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
    <edit-item :item="selectedItem" :mode="mode" @close="deselectItem" @reload="reloadItemsCallback" />
    <div class="mt-3 d-flex">
      <v-spacer />
      <v-btn @click="addItem">{{ $t(`actions.${mode}New`) }}</v-btn>
    </div>
  </div>
</template>

<script>
import generalFormMixin from '~/components/mixins/generalFormMixin'
import EditItem from '~/components/admin/EditItem'

export default {
  components: {EditItem},
  mixins: [generalFormMixin],
  props: ['mode'],
  data () {
    return {
      items: [],
      selectedItem: null
    }
  },
  computed: {
    modeMName () {
      return this.mode + 's'
    },
    headers () {
      if (this.mode === 'user') {
        return [
          {text: this.$t('admin.users.id'), value: 'id'},
          {text: this.$t('admin.users.name'), value: 'name'},
          {text: this.$t('admin.users.email'), value: 'email', format: 'email'},
          {text: this.$t('admin.users.role'), value: 'role'},
          {text: this.$t('admin.users.organization'), value: 'organizationNames'},
          {text: this.$t('admin.users.devices'), value: 'deviceNames'},
          {text: '', value: 'controls', sortable: false, format: 'controls'}
        ]
      } else if (this.mode === 'organization') {
        return [
          {text: this.$t('admin.organizations.id'), value: 'id'},
          {text: this.$t('admin.organizations.name'), value: 'name'},
          {text: this.$t('admin.organizations.devices'), sortable: false, value: 'devices', format: 'devices'},
          {text: '', value: 'controls', sortable: false, format: 'controls'}
        ]
      } else {
        return []
      }
    }
  },
  created () {
    this.loadItems()
  },
  methods: {
    getItemValue (item, header) {
      const pathParts = (header.field || header.value || '').split('.')
      let result = item
      for (let i = 0; i < pathParts.length; i++) {
        if (result && Object.prototype.hasOwnProperty.call(result, pathParts[i])) {
          result = result[pathParts[i]]
        } else {
          return header.emptyPlaceholder || null
        }
      }
      return result || header.emptyPlaceholder
    },
    loadItems (force) {
      this.setLoading(this.mode)
      this.resetAlert()
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'admin.' + this.modeMName, force}))
        .then((response) => {
          this.items = response.data.map(this.prepareItem)
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    prepareItem (item) {
      if (this.mode === 'user') {
        let organizations = item.organizations || item.organization || []
        if (!_.isArray(organizations) && _.isObject(organizations)) {
          throw new Error('user.organizations || user.organization must be an array', item)
        }
        return {
          ...item,
          organizations,
          organizationNames: organizations.map(organization => organization.name).join(', '),
          all_devices: !item.devices || !item.devices.length,
          deviceNames: item.devices && item.devices.length ? item.devices.map(device => device.name).join(', ') : this.$t('form.user.allDevicesShort')
        }
      } else {
        return item
      }
    },
    reloadItemsCallback (callback) {
      return Promise.resolve()
        .then(() => this.loadItems(true))
        .then(() => callback && callback())
    },
    selectItem (item) {
      this.selectedItem = item
    },
    deselectItem () {
      this.selectedItem = null
    },
    addItem () {
      this.selectedItem = {all_devices: true}
    },
    deleteItem (item) {
      this.setLoading('delete-' + item.id)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: `admin.${this.mode}.delete`, data: {id: item.id}}))
        .then(() => this.loadItems(true))
        .then(() => {
          this.$snack({type: 'success', message: this.$t(`success.${this.mode}Deleted`)})
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
