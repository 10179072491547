<template>
  <div class="fc-map__page -with-header -full-width">
    <div class="fc-map__page-header">
      <v-row align="center">
        <v-col>
          <h1 class="title">{{ $t('menu.admin') }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn :to="{name: 'main'}" icon exact>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-tabs
      v-model="tab"
      grow
      dark
      background-color="grey darken-2"
      class="fc-map__page-tabs-container"
    >
      <v-tabs-slider />
      <template v-for="tab in tabs">
        <v-tab :key="tab.key">
          <span class="hidden-xs-only">
            {{ tab.name }}
          </span>
          <v-icon class="hidden-sm-and-up">
            {{ tab.icon }}
          </v-icon>
        </v-tab>
        <v-tab-item :key="tab.key">
          <div class="fc-map__page-body pb-3">
            <admin-items :mode="tab.mode" />
          </div>
        </v-tab-item>
      </template>
    </v-tabs>
    <div class="fc-map__page-footer" />
  </div>
</template>

<script>
import AdminItems from '~/components/admin/AdminItems'

export default {
  components: {AdminItems},
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    user () { return this.$store.state.auth.user },
    tabs () {
      const out = []
      if (this.user.permissions.includes('Create User')) {
        out.push({key: 'users', mode: 'user', icon: 'mdi-account-multiple', name: this.$t('admin.menu.users')})
      }
      if (this.user.permissions.includes('Create Organization')) {
        out.push({key: 'organizations', mode: 'organization', icon: 'mdi-home-city', name: this.$t('admin.menu.organizations')})
      }
      return out
    }
  }
}
</script>
