<template>
  <div class="fc-map__page-body">
    <div class="body-1 my-3">
      <v-alert v-if="!settings.length" type="info" outlined>
        {{ $t('success.noSystemSettings') }}
      </v-alert>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th>{{ $t('pivot.field') }}</th>
            <th>{{ $t('pivot.value') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="setting in settings" :key="setting.key">
            <td>{{ setting.key }}</td>
            <td>{{ setting.value }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="d-flex flex-wrap justify-end mt-3">
        <v-btn :loading="loading" text class="ml-3" @click="request('pivot')">
          {{ $t('actions.requestPivot') }}
        </v-btn>
        <v-btn :loading="loading" text class="ml-3" @click="request('pump')">
          {{ $t('actions.requestPump') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  mixins: [generalFormMixin],
  props: ['id', 'tab'],
  computed: {
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    settings () {
      const out = []
      if (this.item && this.item.deviceSettings && this.item.deviceSettings.settings) {
        Object.keys(this.item.deviceSettings.settings).forEach((key) => {
          out.push({key, value: this.item.deviceSettings.settings[key]})
        })

        if (this.item.deviceSettings.motohours) {
          Object.keys(this.item.deviceSettings.motohours).forEach((key) => {
            out.push({key, value: this.item.deviceSettings.motohours[key]})
          })
        }
      }
      return out
    }
  },
  methods: {
    request (target) {
      this.setLoading(true)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: `rtu.${target}Request`, data: {id: this.id}}))
        .then(() => this.$store.dispatch('rtu/fetch', {force: true}))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t(`success.${target}Requested`)})
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
