<template>
    <highcharts :options="options"/>
</template>

<script>
export default {
  props: {
    average: {
      type: Number,
      default: 0
    },
    averageColor: {
      type: String,
      default: '#000000'
    }
  },
  computed: {
    showChart () {
      return this.data && !_.isEmpty(this.data) && this.provider
    },
    options () {
      const out = {
        chart: {
          type: 'bar',
          width: 200,
          height: 80,
          backgroundColor: 'transparent'
        }, 
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          bar: {
            borderWidth: 0,
            borderRadius: 3
          }
        },
        xAxis: {
        visible: false
      },
      yAxis: {
        visible: true,
        min: 0,
        max: 100,
        title: {
          text: null
        },
        gridLineWidth: 0,
        labels: {
          y: -2
        }
      },
      series: [
      {
        name: "Fill",
        data: [100],
        color: "gray",
        grouping: false
      },
      {
        name: "Percentage",
        data: [this.average],
        color: this.averageColor,
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'right',
          format: '{point.y}%',
          style: {
            color: 'black',
            textOutline: false,
          }
        }
      }]
      }

      return out
    }
  }
}
</script>