export const state = () => ({
  items: [],
  editingItemId: null,
  editingCoords: null,
  ready: false,
  randomToken: ''
})

export const getters = {
  items (state, getters, rootState) {
    const snapshotToken = rootState.auth.snapshotToken
    return state.items.map((item) => {
      if (item.snapshot && item.snapshot.big && item.snapshot.small) {
        return {
          ...item,
          snapshot: {
            big: item.snapshot.big.replace(/big$/, snapshotToken + '/big?r=' + state.randomToken),
            small: item.snapshot.small.replace(/small$/, snapshotToken + '/small?r=' + state.randomToken),
          }
        }
      } else {
        return item
      }
    })
  }
}

export const mutations = {
  setItems (state, items) {
    state.items = items
  },
  setReady (state, value) {
    state.ready = value
  },
  setEditingItemId (state, value) {
    state.editingItemId = value
  },
  setEditingCoords (state, value) {
    state.editingCoords = value
  },
  updateEditingCoords (state, value) {
    state.editingCoords = {...(state.editingCoords || {}), ...value}
  },
  updateRandomToken (state, value) {
    state.randomToken = value
  }
}

export const actions = {
  fetch ({commit, dispatch}, options) {
    if (!options) { options = {} }
    return Promise.resolve()
      .then(() => dispatch('fetch/fetch', {path: 'rtu.list', force: options.force}, {root: true}))
      .then((response) => {
        commit('setItems', response.data)
      })
      .then(() => {
        commit('setReady', true)
      })
  },
  setEditingItemId ({commit}, id) {
    commit('setEditingItemId', id)
    return Promise.resolve()
  },
  setEditingCoords ({commit}, coords) {
    commit('setEditingCoords', coords)
    return Promise.resolve()
  },
  updateEditingCoords ({commit}, coords) {
    commit('updateEditingCoords', coords)
    return Promise.resolve()
  },
  updateRandomToken ({commit}) {
    commit('updateRandomToken', Math.floor(Math.random() * 1000))
    return Promise.resolve()
  }
}
