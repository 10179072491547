export default [{
  key: 'move', options: [{id: 'frw', icon: 'rotate-frw'}, {id: 'rev', icon: 'rotate-rev'}, {id: 'off', icon: 'stop'}]
}, {
  key: 'irrigation', options: [{id: 'on', icon: 'irrigation-on'}, {id: 'off', icon: 'irrigation-off'}]
}, {
  key: 'acc', options: [{id: 'on', icon: 'battery-on'}, {id: 'off', icon: 'battery-off'}]
}, {
  key: 'autoRev', options: [{id: 'on', icon: 'rev-on'}, {id: 'off', icon: 'rev-off'}]
}, {
  key: 'autoStart', options: [{id: 'on', icon: 'cycle-on'}, {id: 'off', icon: 'cycle-off'}]
}]
