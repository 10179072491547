import Vue from 'vue'
import Vuex from 'vuex'
import * as i18n from './i18n'
import * as auth from './auth'
import * as fetch from './fetch'
import * as ux from './ux'
import * as map from './map'
import * as rtu from './rtu'
import * as station from './station'
import * as meteos from './meteos'
import * as field from './field'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    i18n: {...i18n, namespaced: true},
    auth: {...auth, namespaced: true},
    fetch: {...fetch, namespaced: true},
    ux: {...ux, namespaced: true},
    map: {...map, namespaced: true},
    rtu: {...rtu, namespaced: true},
    station: {...station, namespaced: true},
    meteos: {...meteos, namespaced: true},
    field: {...field, namespaced: true},
  }
})

export default store
