<template>
  <div class="fc-map__page-body"  style="overflow: unset">
    <div class="body-1">
      <v-card-text>
        <v-row>
          <v-col>
            <fields v-model="filterData" :fields="filterFields" />
          </v-col>
          <v-col cols="auto" class="mt-3">
            <v-btn :loading="loading" color="primary" @click="find">{{ $t('actions.find') }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <div class="fc-map__page-body pb-3">
      <WatermarkChart 
        :data="chartData.Watermarks" 
        :dates="chartData.dates"
        :provider="chartData.prov" 
        :reversed="chartData.prov === 1"
        :soil="chartData['Soil']"
      />
      <div>
        <v-card-text v-if="editingInstance">
        <table class="fc-map__table">
          <tbody>
            <tr class="fc-map__table-row">

              <th>Калибровка датчиков</th>
              <td class="fc-map__table-cell">
                <field-control v-model="instance['Soil'].wilting_point" :disabled="!editingInstance" :field="WiltingPointField" />
              </td>
              
              <td class="fc-map__table-cell">
                <field-control v-model="instance['Soil'].start_watering" :disabled="!editingInstance" :field="StartWateringField" />
              </td>
              
              <td class="fc-map__table-cell">
                <field-control v-model="instance['Soil'].stop_watering" :disabled="!editingInstance" :field="StopWateringField" />
              </td>
              
              <!-- <td class="fc-map__table-cell">
                <field-control v-model="instance['Soil'].smallest_field_capacity" :disabled="!editingInstance" :field="SmallestCapacityField" />
              </td> -->
              
              
            </tr>
          </tbody>
        </table>
        </v-card-text>
        <v-row class="mb-3" :loading="loading">
          <v-col class="d-flex flex-wrap justify-end">
            <template v-if="editingInstance">
              <v-btn :loading="loading" text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
              <v-btn :loading="loading" color="primary" @click="save">{{ $t('actions.save') }}</v-btn>
            </template>
            <template v-else>
              <!-- <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->
              <v-btn text @click="start">{{ $t('actions.editWatermarkSensor') }}</v-btn>
            </template>
          </v-col>
        </v-row>
      </div>
      <PrecipitationChart
        :data="chartData"
      />
      <SoilTemperatureChart 
        :data="chartData['Soil temperature']" 
        :dates="chartData.dates"
        :provider="chartData.prov" 
      />
      <AirTemperatureChart
        :data="chartData['HC Air temperature']"
      />
      <AirTemperature2Chart
        :data="chartData"
      />
      <VICChart
        :data="chartData['VIC']" 
        :dates="chartData.dates"
        :provider="chartData.prov" 
      />
      <RelativeHumidityChart
        :data="chartData"
      />
      <!-- <SolarRadiationChart
        :data="chartData['Solar radiation']"
      /> -->
      
      <WindSpeedChart
        :data="chartData"
      />

      <WindChart 
        :data="chartData" 
      />

      <DailyET0Chart
        :data="chartData"
      />
    </div>
  </div>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'
import WatermarkChart from './WatermarkChart'
import SoilTemperatureChart from './SoilTemperatureChart'
import VICChart from './VICChart'
import AirTemperatureChart from './AirTemperatureChart'
import AirTemperature2Chart from './AirTemperature2Chart'
// import SolarRadiationChart from './SolarRadiationChart'
import RelativeHumidityChart from './RelativeHumidityChart'
import PrecipitationChart from './PrecipitationChart'
import WindSpeedChart from './WindSpeedChart'
import WindChart from './WindChart'

import DailyET0Chart from './DailyET0Chart'

export default {
  components: {
    WatermarkChart,
    SoilTemperatureChart,
    VICChart,
    AirTemperatureChart,
    AirTemperature2Chart,
    // SolarRadiationChart,
    RelativeHumidityChart,
    PrecipitationChart,
    WindSpeedChart,
    WindChart,
    DailyET0Chart
  },
  mixins: [generalFormMixin],
  props: ['id'],
  data() {
    return {
      loading: false,
      chartData: {},
      filterData: {
        from: null,
        to: null,
        period: null
      },
      instance: [],
      editingInstance: false,
    }
  },
  computed: {
    WiltingPointField () { return configFields.soil.find(field => field.key === 'wilting_point') },
    StartWateringField () { return configFields.soil.find(field => field.key === 'start_watering') },
    StopWateringField () { return configFields.soil.find(field => field.key === 'stop_watering') },
    SmallestCapacityField () { return configFields.soil.find(field => field.key === 'smallest_field_capacity') },
    fields () { return configFields.soil },
    filterFields () { return configFields.stationFilter },
    updateInstanceTrigger () {
      return JSON.stringify({id: this.id, item: this.item})
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.loadData()
      }
    },
    // updateInstanceTrigger: {
    //   immediate: true,
    //   handler () {
    //     if (this.editingInstance) { return false }
    //     console.log(this.chartData['Soil'])
    //     this.instance = this.prepareWatermarkInstance(this.chartData)
    //   }
    // },
  },
  methods: {
    prepareWatermarkInstance (item) {
      this.chartData = {}
      // console.log(item)
      if (item) {
        const instance = _.cloneDeep(item)
        this.chartData = item

        return instance
      } else {
        return []
      }
    },
    find () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.filterData, this.filterFields)
      return Promise.resolve()
          .then(() => this.$store.dispatch('fetch/fetch', {path: 'station.request', data: {id: this.id, data: data}}))
          .then((response) => {
            this.chartData = response.data
          })
          .catch(this.handleError)
          .finally(() => {
            this.setLoading(false)
          })

    },
    loadData (force) {
      this.chartData = {}
      return Promise.resolve()
          .then(() => this.$store.dispatch('fetch/fetch', {path: 'station.request', data: {id: this.id}, force}))
          .then((response) => {
            this.chartData = response.data
            
            this.filterData.from = moment(this.chartData.dates[0] + '.2023', 'DD.MM.YYYY').format('YYYY-MM-DD')
            this.filterData.to = moment(_.last(this.chartData.dates) + '.2023', 'DD.MM.YYYY').format('YYYY-MM-DD')
            this.filterData.period = 'День';

            this.instance = this.prepareWatermarkInstance(this.chartData)
          })
          .catch(this.handleError)
          .finally(() => {
            this.setLoading(false)
          })
    },
    start () {
      this.editingInstance = true
    },
    save () {
      this.setLoading(true)
      // const data = this.prepareWatermarkFormData(this.instance.soil)
      const data = this.instance['Soil']
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'station.update', data: {id: this.id, data}}))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t('success.watermarkSettingsUpdated')})
          this.cancel()
          this.loadData()
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    cancel () {
      this.instance = this.prepareWatermarkInstance(this.chartData)
      this.editingInstance = false
    },
  }
}
</script>
