<template>
  <highcharts v-if="showChart" :options="options" />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    },
    dates: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    showChart () {
      return this.data.Temperature && !_.isEmpty(this.data.Temperature)
    },
    options () {
      if (!this.showChart) {
        return null
      }
      // const maxValue = Math.max(...$.map(this.data.Temperature, val => val))
      // const minValue = Math.min(...$.map(this.data.Temperature, val => val))
      return {
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 500,
        },
        credits: {
          enabled: false
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories: this.data.dates,
          // tickInterval: 1,
          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          tickInterval: 5,
          // max: maxValue + (maxValue * 0.5),
          // min: minValue + (minValue * 0.5),
          startOnTick: true,
          endOnTick: true,
          title: {
            text: this.$t('charts.air_temperature') + ', °C',
            margin: 15
          },
          labels: {
            formatter: function () {
              return this.value + '°';
            }
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
          },
          column: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                if (this.y > 0) {
                  return this.y;
                }
              },
              crop: false,
              overflow: 'none'
            }
          },
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          enabled: true
        },
        series: [{
          name: 'Макс',
          data: this.data.Temperature.max
        },
        {
          name: 'Среднее',
          data: this.data.Temperature.avg
        },
        {
          name: 'Мин',
          data: this.data.Temperature.min
        },
        ]
      }
    }
  }
}
</script>
