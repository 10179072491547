<template>
  <highcharts v-if="showChart" :options="options" />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => []
    }
  },
  computed: {
    showChart () {
      return this.data['Wind direction'] && !_.isEmpty(this.data['Wind direction'])
    },
    options () {
      if (!this.showChart) {
        return null
      }
      return {
        chart: {
          type: '',
          polar: true          
        },
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        pane: {
          startAngle: 0,
          endAngle: 360
        },
        xAxis: {
          tickInterval: 45,
          min: 0,
          max: 360,
          labels: {
            format: '{value}°'
          }
        },
        yAxis: {
          min: 0
        },
        plotOptions: {
          series: {
            animation: false,
            pointStart: 0,
            pointInterval: 45
          },
          column: {
            column: {
              pointPadding: 0,
              groupPadding: 0
            }
          },
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            return this.x
          }
        },
        legend: {
          enabled: false
        },
        series: [{
          type: 'area',
          // name: 'Скорость, мс',
          data: this.data['Wind direction'],
        }]
        // series: [{
        //   yAxis: 0,
        //   zIndex: 5,
        //   events:{
        //     legendItemClick: function () {
        //       return false
        //     }
        //   },
        //   data: this.data,
        //   enableMouseTracking: false,
        //   type: 'areaspline',
        //   lineColor: 'rgba(255, 255, 153, 0.75)',
        //   color: 'rgba(255, 255, 153, 0.75)',
        //   lineWidth: 1,
        //   marker: {
        //     symbol: 'circle',
        //     enabled: true
        //   }
        // }]
      }
    }
  }
}
</script>
