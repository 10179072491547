<template>
  <v-text-field
        type="text"
        v-mask="'##:##'"
        ref="input"
        :value="innerValue"
        v-bind="inputOptionsComputed"
        @input="parseInput"
      />
</template>
<script>
import moment from 'moment'
import dateTimeFormat from '~/components/mixins/dateTimeFormat'
import dateTimeMixin from '~/components/mixins/dateTimeMixin'

export default {
  mixins: [dateTimeFormat, dateTimeMixin],
  data () {
    const out = {
      innerValue: '',
      innerErrors: this.errors || [],
      pickerVisible: false,
      pickerOptionsPosition: {},
      pickerWidth: 330,
      pickerHeight: 250,
      pickerNudge: 0,
      inputFocused: false,
      readonlyOnMobile: false,
      pickerTimeIndex: null
    }
    const time = this.parseTime(this.value, 'time')
    if (time.valid) {
      out.innerValue = time.formattedTime
    } else {
      out.innerValue = this.value
      out.innerErrors.push('Invalid time')
    }
    return out
  },
  computed: {
    menuOptionsComputed () {
      return {
        ...this.menuOptions,
        ...this.pickerOptionsPosition
      }
    },
    pickerOptionsComputed () {
      const out = {
        startHour: 7,
        endHour: 22,
        stepMinute: 15,
        ...this.pickerOptions,
        ...this.pickerOptionsPosition
      }
      return out
    },
    pickerTimes () {
      const out = []
      const o = this.pickerOptionsComputed
      for (let i = o.startHour * 60; i <= o.endHour * 60; i += o.stepMinute) {
        out.push({
          name: this.formatTime(this.getTime(i)),
          value: this.getTime(i, 'HH:mm')
        })
      }
      return out
    },
    innerValuePickerVisible () {
      return {
        innerValue: this.innerValue,
        // pickerVisible: this.pickerVisible
      }
    }
  },
  watch: {
    value (value) {
      const time = this.parseTime(value, 'time')
      if (time.valid) {
        this.innerErrors = []
        if (!this.focused) {
          this.innerValue = time.formattedTime
        }
      } else {
        this.innerErrors = ['Invalid time']
        if (!this.focused) {
          this.innerValue = value
        }
      }
    },
    pickerTimeIndex (index) {
      this.$refs.menu.listIndex = index
    },
    
    innerValue (value) {
      if (!this.autosave) {
        const time = this.parseTime(this.innerValue, 'time')
        if (time.empty) {
          this.emitChange(null)
        } else if (time.valid) {
          this.emitChange(time.time)
        }
      }
    }
  },
  methods: {
    parseTime (value, mode) {
      // The mode might be either `string` or `time`;
      // `string` mode for 02:45 gives {hours: 2, minutes: 45, ampm: 'pm'};
      // `time` mode (24h format) for 02:45 gives {hours: 2, minutes: 45, ampm: 'am'};
      value = (value || '').toLowerCase().trim()
      const time = {valid: true, empty: false, time: null}
      if (!value.length) {
        time.empty = true
        return time
      }
      let digits = (value || '').split(/[^\d]+/).filter(part => !!part).map(part => parseInt(part))
      if (digits.length === 1 && digits[0].toString().length === 3) {
        const n1 = parseInt(digits[0].toString().substr(0, 1))
        const n2 = parseInt(digits[0].toString().substr(1))
        digits = [n1, n2]
      } else if (digits.length === 1 && digits[0].toString().length === 4) {
        const n1 = parseInt(digits[0].toString().substr(0, 2))
        const n2 = parseInt(digits[0].toString().substr(2))
        digits = [n1, n2]
      }
      if (/a/.test(value) && /p/.test(value)) {
        time.valid = false
      } else if (/a/.test(value)) {
        time.ampm = 'am'
      } else if (/p/.test(value)) {
        time.ampm = 'pm'
      }
      if (digits.length === 0 || digits.length > 2) {
        time.valid = false
      } else {
        const hours = digits[0]
        const minutes = digits.length > 1 ? digits[1] : 0
        if (minutes > 60) {
          time.valid = false
        } else {
          time.minutes = minutes
        }
        if (hours > 24 || hours < 0) {
          time.valid = false
        } else if (mode === 'time') {
          time.ampm = hours > 11 ? 'pm' : 'am'
          time.hours = hours
        } else if (hours > 12) {
          time.ampm = 'pm'
          time.hours = hours
        } else if (hours === 12) {
          if (time.ampm === 'am') {
            time.hours = 0
          } else {
            time.ampm = 'pm'
            time.hours = hours
          }
        } else if (hours > 6 && hours < 12) {
          if (time.ampm === 'pm') {
            time.hours = hours + 12
          } else {
            time.ampm = 'am'
            time.hours = hours
          }
        } else if (hours > 0) {
          if (time.ampm === 'am') {
            time.hours = hours
          } else {
            time.ampm = 'pm'
            time.hours = hours + 12
          }
        } else if (hours === 0) {
          time.ampm = 'am'
          time.hours = hours
        } else {
          time.valid = false
        }
      }
      if (time.valid) {
        if (time.empty) {
          time.time = null
          time.formattedTime = ''
        } else {
          time.m = moment(time.hours + ':' + time.minutes, 'H:m')
          time.time = time.m.format('HH:mm')
          time.formattedTime = this.formatTime(time.time)
        }
      }
      return time
    },
    setPickerPosition () {
      this.pickerWidth = this.determinePickerWidth()
      this.pickerOptionsPosition = this.determinePickerPosition()
    },
    setTime () {
      const time = this.parseTime(this.innerValue, 'string')
      if (time.valid) {
        this.innerValue = time.formattedTime
        this.emitChange(time.time)
      } else {
        this.innerErrors.push('Invalid time')
      }
      this.pickerVisible = false
    },
    setTimeFromPicker (time) {
      this.innerValue = time
      this.setTime()
    },
    parseInput (value) {
      this.innerValue = value
      this.innerErrors = []
      this.pickerVisible = true
    },
    handleKeydown (e) {
      if (e.key === 'Enter') {
        if (this.pickerVisible) {
          if (this.pickerTimeIndex === 0 || this.pickerTimeIndex > 0) {
            this.setTimeFromPicker(this.pickerTimes[this.pickerTimeIndex].name)
          } else {
            this.setTime()
          }
        } else {
          this.showPicker()
        }
      } else if (e.key === 'Tab') {
        if (this.pickerVisible) {
          if (this.pickerTimeIndex === 0 || this.pickerTimeIndex > 0) {
            this.setTimeFromPicker(this.pickerTimes[this.pickerTimeIndex].name)
          } else {
            this.setTime()
          }
        } else {
          this.setTime()
        }
      } else if (e.key === 'Escape') {
        if (this.pickerVisible && (this.pickerTimeIndex === 0 || this.pickerTimeIndex > 0)) {
          this.pickerTimeIndex = null
        } else {
          const time = this.parseTime(this.value, 'time')
          if (time.valid) {
            this.innerValue = time.formattedTime
          } else {
            this.innerValue = this.value
            this.innerErrors = ['Invalid time']
          }
          this.hidePicker()
        }
      } else if (e.key === '+' || e.key === '-') {
        const time = this.parseTime(this.innerValue, 'value')
        if (time.valid && !time.empty) {
          e.preventDefault()
          const m = time.m.clone()
          const step = this.pickerOptionsComputed.stepMinute
          let addon = m.format('m') % step
          if (e.key === '+') addon = step - addon
          else addon = (addon ? 0 : -step) - addon
          this.innerValue = this.formatTime(m.add(addon, 'm').format('HH:mm'))
        }
      } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        if (this.pickerVisible) {
          e.preventDefault()
          if (this.pickerTimeIndex === null) {
            this.pickerTimeIndex = 0
          } else {
            this.pickerTimeIndex += (e.key === 'ArrowDown' ? 1 : -1)
            if (this.pickerTimeIndex < 0) this.pickerTimeIndex = 0
            if (this.pickerTimeIndex >= this.pickerTimes.length) this.pickerTimeIndex = this.pickerTimes.length - 1
          }
        }
      }
    },
    pickerClickOutside () {
      this.setTime()
      this.hidePicker()
    }
  }
}
</script>
