<template>
  <v-tooltip v-if="alert && (mode === 'tooltip')" v-bind="$attrs">
    <template #activator="{on}">
      <v-icon
        v-on="on"
        :color="alert.type"
      >
        {{ alert.icon }}
      </v-icon>
    </template>
    <span>
      {{ $t('backEndStatuses.' + alert.msg) }}
    </span>
  </v-tooltip>
  <v-alert
    v-else-if="alert && (mode === 'alert')"
    v-bind="$attrs"
    :icon="alert.icon"
    :color="alert.type"
    outlined
  >
    {{ $t('backEndStatuses.' + alert.msg) }}
  </v-alert>
  <div
    v-else-if="alert && (mode === 'title')"
    v-bind="$attrs"
    class="title d-flex align-center"
  >
    <v-icon
      :color="alert.type"
      class="mr-1"
    >
      {{ alert.icon }}
    </v-icon>
    <span>
      {{ $t('backEndStatuses.' + alert.msg) }}
    </span>
  </div>

</template>

<script>
export default {
  props: ['item', 'mode'],
  computed: {
    alert () {
      if (this.item && this.item.deviceStatus) {
        if (this.item.deviceStatus.error) {
          return {
            type: 'error',
            icon: 'mdi-alert',
            msg: this.item.deviceStatus.error
          }
        } else if (this.item.deviceStatus.warning) {
          return {
            type: 'warning',
            icon: 'mdi-alert',
            msg: this.item.deviceStatus.warning
          }
        }
      }
      return null
    }
  }
}
</script>